#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-loading {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  z-index: 22;
}