/deep/ .ant-modal {
  width: 10rem !important;
}

.loginDialog /deep/ .ant-modal-body {
  padding: 0.6rem 2rem 0.5rem;
  min-height: 6.1rem;
  position: relative;
}

.loginDialog /deep/ .ant-form-item-children {
  display: flex;
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.45rem;
}

.title span {
  padding-bottom: 0.26rem;
  line-height: 1;
  position: relative;
  font-size: 0.32rem;
  color: #333333;
}

.title span::before {
  content: "";
  width: 0.4rem;
  height: 0.05rem;
  background-color: #00ffc0;
  border-radius: 0.02rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.qrcode-btn {
  width: 0.8rem;
  position: absolute;
  left: 0.05rem;
  top: 0.05rem;
}

.qrcode-btn img {
  max-width: 100%;
  width: 100%;
  display: block;
  cursor: pointer;
}

.qrcode-btn::before {
  content: "";
  width: 0;
  height: 0;
  border: 0.565685rem solid transparent;
  position: absolute;
  right: 0;
  top: 0;
  border-top-color: #fff;
  transform-origin: right top;
  transform: rotateZ(-45deg);
  z-index: 2;
}

@media screen and (min-width: 1800px /*no*/) and (max-height: 800px /*no*/) {
  /deep/ .ant-modal-content {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 1024px /*no*/) {
  /deep/ .ant-modal-content {
    transform: scale(1);
  }

  .loginDialog /deep/ .ant-modal-body {
    padding: 0.6rem 0.6rem 0.5rem;
    min-height: 7.1rem;
    position: relative;
  }

  .loginDialog /deep/ .ant-form-item-children {
    display: flex;
  }

  .qrcode-btn {
    display: none;
  }
}