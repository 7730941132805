.header {
  background-color: #000f23;
  flex: 0;
  position: relative;
  z-index: 20;
}

.header .logo {
  float: left;
  padding: 0.245rem 0;
}

.header .logo img {
  width: 1.96rem;
  height: 0.51rem;
  object-fit: contain;
}

.header nav {
  float: left;
  display: flex;
  margin-left: 0.95rem;
}

.header nav .item {
  padding: 0 0.25rem;
}

.header nav .item.active a {
  color: #00ffc0;
}

.header nav .item.active em::before {
  width: 100%;
}

.header nav em {
  display: block;
  line-height: 1rem;
  position: relative;
}

.header nav em::before {
  content: '';
  width: 0;
  height: 0.05rem;
  background-color: #00ffc0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  transition: 0.3s;
}

.header nav a {
  display: inline-block;
  vertical-align: top;
  font-size: 0.18rem;
  color: #fff;
  font-weight: bold;
}

.header nav a:hover {
  color: #00ffc0;
}

.header .search {
  float: right;
  padding: 0.3rem 0;
}

.header .search .input-box {
  background-color: #fff;
  border-radius: 0.03rem;
  padding: 0 0.15rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  width: 3rem;
  margin-right: 0.1rem;
}

.header .search .input-box input {
  font-size: 0.16rem;
  line-height: 0.4rem;
  flex: 1;
}

.header .search .input-box input::placeholder {
  color: #bbbbbb;
}

.header .search .input-box .icon_search {
  font-size: 0.16rem;
  cursor: pointer;
  flex-shrink: 0;
  color: #bbb;
}

.header .login {
  float: right;
  padding: 0.3rem 0;
  min-width: 1.2rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.header .login .btn {
  height: 0.4rem;
  width: 100%;
  text-align: center;
  color: #000000;
  border-radius: 0.03rem;
  background-color: #00ffc0;
  border-color: #00ffc0;
  line-height: 0.4rem;
  font-weight: bold;
}

.header .login .btn:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  box-shadow: 0 0 0.1rem 0 #79f9d9;
}

.header .login .avatar {
  font-size: 0.16rem;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.header .login .avatar .ant-avatar {
  margin-left: 0.05rem;
}

.header .login .avatar:hover .dropdown {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.header .login .logout {
  width: auto;
  padding: 0 0.1rem;
  margin-left: 0.05rem;
}

.header .login .username {
  color: #fff;
  min-width: 0.63rem;
  line-height: 1;
  font-weight: bold;
  font-size: 0.16rem;
  padding-left: 0.1rem;
}

.header .login :deep(.ant-avatar-sm) {
  width: 0.26rem;
  height: 0.26rem;
  background-color: transparent;
}

.header .login .icon_user {
  font-size: 0.26rem;
  color: #fff;
}

.header .login :deep(.ant-avatar-string) {
  transform: scale(1) translateX(-50%) !important;
}

.header .login .dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  padding-top: 0.15rem;
  transform: translateY(-0.05rem);
  opacity: 0;
  transition: 0.3s;
  visibility: hidden;
}

.header .login .dropdown .inner {
  padding: 0.2rem 0.2rem 0.1rem;
  width: 2.4rem;
  background-color: #fff;
  border-radius: 0.1rem;
  position: relative;
  box-shadow: 0 0.02rem 0.08rem rgba(0, 0, 0, 0.15);
}

.header .login .dropdown .inner::before {
  content: '';
  width: 0;
  height: 0;
  border: 0.08rem solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  right: 0.4rem;
  top: -0.13rem;
}

.header .login .dropdown .inner .item {
  line-height: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-bottom: 0.01rem solid #e5e5e5;
  transition: 0.3s;
}

.header .login .dropdown .inner .item:hover {
  background-color: rgba(0, 255, 192, 0.2);
}

.header .login .dropdown .inner .item .left {
  color: #000000;
  display: flex;
  align-items: center;
}

.header .login .dropdown .inner .item .svg-icon {
  font-size: 0.16rem;
  color: #888;
  margin-right: 0.1rem;
}

.header .login .dropdown :deep(.ant-badge-count) {
  background-color: #00ffc0;
  color: #000;
}