body .ant-modal-content {
  border-radius: 0.1rem;
  overflow: hidden;
}

body .ant-modal-content .ant-modal-close-x svg {
  color: #bbbbbb;
  transition: 0.3s;
}

body .ant-modal-content .ant-modal-close-x .anticon-close:hover svg {
  color: #666;
}

body .ant-input {
  border: 0.01rem solid  transparent;
}

body .ant-input:hover {
  border-color: #79f9d9 !important;
}

body .ant-input:focus {
  border-color: #79f9d9 !important;
  box-shadow: 0 0 0 0.01rem #79f9d9 !important;
}

body .has-error .ant-input:hover {
  border-color: #f5222d !important;
}

body .has-error .ant-input:focus {
  border-color: #f5222d !important;
  box-shadow: 0 0 0 0.01rem #f52223 !important;
}

body .ant-select {
  font-size: 0.18rem;
}

body .ant-select .ant-select-selection__placeholder {
  color: #bbbbbb;
}

body .ant-select-selection:focus .ant-select-selection,
body .ant-select-focused .ant-select-selection,
body .ant-select-open .ant-select-selection {
  box-shadow: 0 0 0 0.01rem #79f9d9;
  border-color: transparent;
}

body .ant-select-selection {
  height: 0.4rem;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 0.03rem;
}

body .ant-select-selection:hover {
  border-color: #79f9d9;
  box-shadow: 0 0 0 0.01rem #79f9d9;
}

body .ant-select-selection .ant-select-selection__rendered {
  line-height: 0.38rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

body .ant-select-arrow {
  right: 0.2rem;
}

body .ant-select-lg .ant-select-selection {
  height: 0.5rem;
}

body .ant-select-lg .ant-select-selection .ant-select-selection__rendered {
  line-height: 0.48rem;
}

body .pagenation {
  text-align: center;
}

body .ant-pagination-prev,
body .ant-pagination-next,
body .ant-pagination-jump-prev,
body .ant-pagination-jump-next,
body .ant-pagination-item {
  width: 0.5rem;
  height: 0.5rem !important;
  line-height: 0.5rem !important;
  font-size: 0.16rem;
  border-radius: 0.03rem;
  background-color: #f5f5f5;
  border: 0 !important;
  overflow: hidden;
}

body .ant-pagination-prev a,
body .ant-pagination-next a,
body .ant-pagination-jump-prev a,
body .ant-pagination-jump-next a,
body .ant-pagination-item a {
  color: #888888;
}

body .ant-pagination-prev:hover,
body .ant-pagination-next:hover,
body .ant-pagination-jump-prev:hover,
body .ant-pagination-jump-next:hover,
body .ant-pagination-item:hover {
  background-color: #00fec0;
}

body .ant-pagination-prev:hover a,
body .ant-pagination-next:hover a,
body .ant-pagination-jump-prev:hover a,
body .ant-pagination-jump-next:hover a,
body .ant-pagination-item:hover a {
  color: #000;
}

body .ant-pagination-item-active {
  background-color: #00fec0;
}

body .ant-pagination-item-link {
  border: 0 !important;
  background-color: transparent !important;
}

body .ant-pagination-item-link:hover .anticon {
  color: #000;
}

body .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
body .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #888888;
}

body .ant-pagination-prev {
  margin-right: 0.2rem;
  font-weight: bold;
  width: 0.8rem;
  border: 0;
}

body .ant-pagination-next {
  margin-left: 0.2rem;
  font-weight: bold;
  width: 0.8rem;
  border: 0;
}

body .ant-pagination-item-active {
  background-color: #00fec0 !important;
}

body .ant-pagination-item-active a {
  color: #000 !important;
}

body .ant-pagination-item:focus a {
  color: #000 !important;
}

body .ant-pagination-next:focus .ant-pagination-item-link,
body .ant-pagination-next:hover .ant-pagination-item-link {
  color: #000 !important;
}

body .ant-btn-primary {
  border-color: #79f9d9;
  background-color: #00ffc0;
  color: #000000;
}

body .ant-btn-primary:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  color: #000;
}

body .ant-btn-primary:focus {
  border-color: #79f9d9;
  background-color: #00ffc0;
  color: #000000;
}