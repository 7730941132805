.test-enter-active,
.test-leave-active {
  transition: opacity 0.2s;
}

.test-enter,
.test-leave-active {
  opacity: 0;
}

/* / 禁用iPhone中Safari的字号自动调整 / */

html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  font-family: 'HarmonyOS_Sans_SC', 'Alibaba', 'Miscrosoft YaHei', 'Arial, sans-serif';
}

body {
  font-size: 0.16rem;
  overflow-x: hidden;
  text-indent: unset !important;
}

/* / 去除iPhone中默认的input样式 / */

input[type='submit'],
input[type='reset'],
input[type='button'],
input:focus,
button:focus,
select:focus,
textarea:focus {
  outline: none;
}

input {
  -webkit-appearance: none;
  resize: none;
  border-radius: 0;
}

body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* / 设置HTML5元素为块 / */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* / 图片自适应 / */

img {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* / ie8 / */
  -ms-interpolation-mode: bicubic;
  /* /为了照顾ie图片缩放失真/ */
}

body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
}

em,
i {
  font-style: normal;
}

.clearfix:after {
  content: '';
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

.clearfix {
  zoom: 1;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #00ffc0;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

img {
  border: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

pre {
  line-height: inherit;
  font-size: inherit;
  display: block;
  width: 100%;
  word-break: break-word;
  white-space: pre-line;
  font-family: inherit;
  margin-bottom: 0;
}

.dot,
.dot2,
.dot3,
.dot4,
.dot5,
.dot6,
.dot7,
.dot8,
.dot9 {
  display: box;
  display: -webkit-box;
  display: -moz-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.dot {
  -webkit-line-clamp: 1;
}

.dot2 {
  -webkit-line-clamp: 2;
}

.dot3 {
  -webkit-line-clamp: 3;
}

.dot4 {
  -webkit-line-clamp: 4;
}

.dot5 {
  -webkit-line-clamp: 5;
}

.dot6 {
  -webkit-line-clamp: 6;
}

.dot7 {
  -webkit-line-clamp: 7;
}

.dot6 {
  -webkit-line-clamp: 8;
}

.dot9 {
  -webkit-line-clamp: 9;
}

.container {
  max-width: 14rem;
  margin: 0 auto;
  width: 85%;
}

@media (max-width: 1200px) {
  .container {
    width: calc(100% - 0.6rem) !important;
    margin: 0 auto;
  }
}

.ytable {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.ytable-cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.auto-1440 {
  width: 85%;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 100px;
  }
}

@media screen and (max-width: 1920px) {
  html {
    font-size: 5.20833333vw;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 9.765625vw;
  }

  body {
    font-size: 0.24rem;
  }

  .auto-1440 {
    width: 100%;
    max-width: 1000% !important;
    /*no*/
    margin: 0 auto;
    padding: 0 0.3rem;
  }

  ins {
    display: none !important;
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 13.33333333vw;
  }
}

body {
  display: flex;
  flex-direction: column;
}

body #__nuxt,
body #__layout {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dialog_header {
  display: flex;
  justify-content: space-between;
  line-height: 0.6rem;
  margin-bottom: 0.2rem;
  border-bottom: 0.01rem solid #e5e5e5;
}

.dialog_header h2 {
  font-size: 0.24rem;
  color: #333333;
}

.dialog_header .svg-icon {
  color: #bbb;
  cursor: pointer;
  transition: 0.3s;
}

.dialog_header .svg-icon:hover {
  color: #333;
}

.dialog_footer {
  text-align: center;
  padding-bottom: 0.36rem;
  padding-top: 0.36rem;
}

.dialog_footer button {
  padding: 0 0.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.18rem;
  min-width: 1.4rem;
  margin: 0 0.1rem;
}

.dialog_footer button span {
  color: #000;
  font-weight: bold;
}

.dialog_footer .confirm {
  background-color: #00ffc0;
  border-color: #00ffc0;
}

.dialog_footer .cancel {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.dialog_content {
  padding-top: 0.2rem;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #f6a75c;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #f6a75c;
}

.ant-radio-input:focus + .ant-radio-inner {
  border-color: #f6a75c;
}

.compared-info .header,
.model-info .header {
  display: none;
}

.compared-info .footer,
.model-info .footer {
  display: none;
}

.slide-verify canvas {
  position: static !important;
}

.slide-verify .slide-verify-block {
  position: absolute !important;
}

.ind-swiper-ban .bg {
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 100%;
}

.ind-swiper-ban .bg img {
  display: block;
  width: 100%;
}

.ind-swiper-ban .title,
.ind-swiper-ban .subtitle,
.ind-swiper-ban .btn,
.ind-swiper-ban .img {
  position: relative;
  z-index: 2;
}

.indexNav .swipper-slide {
  width: 100%;
}

.ind-swiper-ban .inner {
  width: 96%;
  margin: 0 auto;
  max-width: 12rem;
  text-align: center;
  color: #fff;
  cursor: default;
}

.ind-swiper-ban .inner .title {
  font-size: 0.48rem;
  line-height: 1.2;
  margin-bottom: 0.14rem;
}

.ind-swiper-ban .inner .subtitle {
  font-size: 0.24rem;
  line-height: 1.25;
  letter-spacing: 0.2rem;
  margin-bottom: 0.4rem;
}

.ind-swiper-ban .inner .btn .more {
  min-width: 2rem;
  padding: 0 0.3rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.3rem;
  background-color: #00ffc0;
  font-size: 0.18rem;
  color: #000000;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.3rem;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.ind-swiper-ban .inner .btn .more:hover {
  background-color: #79f9d9;
  /* color: #fff; */
  box-shadow: 0 0 0.1rem 0 #79f9d9;
}

.ind-swiper-ban .inner .img {
  border-radius: 0.6rem;
  border: 0.05rem solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.ind-swiper-ban .inner .img img {
  display: block;
  max-width: 100%;
}

.indexNav {
  position: relative;
}

.indexNav .swiper-pagination-bullet {
  vertical-align: top;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 0.04rem;
  background-color: #fff;
  opacity: 1;
  cursor: pointer;
  transition: 0.3s;
}

.indexNav .swiper-pagination-bullet-active {
  width: 0.3rem;
  background-color: #00ffc0;
}

.indexNav .swiper-pagination {
  font-size: 0;
  bottom: 0.8rem !important;
  z-index: 20;
}

/* fade-transform */

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
}

.fade-transform-leave-to {
  opacity: 0;
}