.input {
  position: relative;
  flex: 1;
  margin-right: 0.1rem;
}

.input:last-child {
  margin-right: 0;
}

.ant-form-item {
  position: relative;
  margin-bottom: 0;
  height: 1rem;
  display: flex;
}

.ant-form-item .icon {
  font-size: 0.24rem;
  position: absolute;
  left: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.ant-form-item :deep(.ant-col) {
  flex: 1;
}

.ant-input {
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.4rem;
  padding: 0 0.7rem;
  background-color: #f5f5f5;
  border-color: transparent;
  font-size: 0.18rem;
}

.ant-input::placeholder {
  color: #888888;
}

.has-error .ant-input:focus,
.has-error .ant-input {
  background-color: #f5f5f5;
  border-color: red;
}

:deep(.ant-form-explain) {
  padding: 0 0.7rem;
}

.forget-pw {
  margin: 0 0 0.4rem;
  line-height: 1;
  display: flex;
  justify-content: space-between;
}

.login_span {
  font-size: 0.16rem;
  color: #888888;
  line-height: 1;
}

.login_span:hover {
  color: #00ffc0;
  cursor: pointer;
}

.login_span.underline {
  border-bottom: 0.01rem solid #888888;
  padding-bottom: 0.01rem ;
}

.login_span.underline:hover {
  border-color: #00ffc0;
}

.login_btn {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.4rem;
  background-color: #00ffc0;
  border-color: #00ffc0;
  font-size: 0.24rem;
  color: #000000;
}

.login_btn:focus,
.login_btn:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  color: #000;
}

.login_btn:active {
  background-color: #00ff7e;
  border-color: #00ff7e;
  color: #000;
}

.login_btn.ver {
  width: auto;
  letter-spacing: unset;
  font-size: 0.18rem;
  width: 1.8rem;
}

.login_btn.not {
  cursor: not-allowed;
}

.form-footer {
  margin-top: 0.2rem;
  display: flex;
  justify-content: center;
}

.qrcode_login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.qrcode_login .img {
  padding: 0.1rem;
  background-color: #f5f5f5;
  border-radius: 0.1rem;
  max-width: 60%;
}

.qrcode_login img {
  max-width: 100%;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 0.68rem #f5f5f5 inset;
  transition: 0.3s;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 0.02rem #79f9d9, 0 0 0 0.68rem #f5f5f5 inset;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #f5f5f5;
}

.verify :deep(.ant-modal) {
  width: auto !important;
}