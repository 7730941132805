.footer {
  background-color: #111;
  padding-top: 0.5rem;
}

.top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.4rem;
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.102);
}

.top .links {
  flex: 1;
}

.top .links h2 {
  font-size: 0.2rem;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 0.15rem;
}

.top .links > div {
  display: flex;
  flex-wrap: wrap;
}

.top .links > div a {
  padding-right: 0.2rem;
  min-width: 25%;
  font-size: 0.16rem;
  line-height: 2;
  color: #888888;
  padding-left: 0.14rem;
  position: relative;
}

.top .links > div a:hover {
  color: #00ffc0;
}

.top .links > div a::before {
  content: '';
  width: 0.05rem;
  height: 0.05rem;
  background-color: #888;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.top .qrcode {
  flex-shrink: 0;
  display: flex;
}

.top .qrcode .item {
  margin-left: 0.2rem;
  text-align: center;
}

.top .qrcode .img {
  width: 1.2rem;
  padding: 0.04rem;
  background-color: #fff;
  display: block;
  min-width: 85px;
}

.top .qrcode .img img {
  display: block;
}

.top .qrcode span {
  font-size: 0.16rem;
  color: #888888;
  line-height: 1.25;
  margin-top: 0.1rem;
  display: block;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.3rem 0;
}

.bottom .links {
  display: flex;
}

.bottom .links a {
  color: #555555;
  position: relative;
  padding: 0 0.1rem;
}

.bottom .links a:first-of-type {
  padding-left: 0;
}

.bottom .links a:not(:last-of-type)::after {
  content: '';
  width: 1px;
  height: 10px;
  background-color: #ffffff;
  opacity: 0.102;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.bottom .links a:hover {
  color: #00ffc0;
}

.bottom .copyright {
  color: #555555;
  display: flex;
}

.bottom .copyright a {
  color: #555555;
  padding: 0 0.1rem;
  position: relative;
}

.bottom .copyright a:hover {
  color: #00ffc0;
}

.bottom .copyright a:last-of-type {
  padding-right: 0;
}

.bottom .copyright a:not(:last-of-type)::after {
  content: '';
  width: 1px;
  height: 10px;
  background-color: #ffffff;
  opacity: 0.102;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}